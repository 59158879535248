
/*******************************************************************
 DOCUMENT READY
 *******************************************************************/
$(document).ready(function () {
   getHelpers.init();
   accordion.init();
});




/********************************************
 HELPERS
 *********************************************/
var getHelpers = {
    init: function() {
        this.relExternal();
		this.copyCode();
        this.searchRedmine();
        this.navDropDown();
        this.showOnTop();
        this.scrollToAnchor();
        this.openNavigation();
        //this.animateElements();
        //this.skipIntro();
        this.enableButton();
		this.colorPicker();
		this.magnific();
    },
    relExternal: function() {
        $(document).on("click", "a[rel=external]", function(e) {
            window.open($(this).attr("href"), "_blank");
            e.preventDefault();
        });
    },

	copyCode: function() {
		$("#copyCode").on("click", function (e) {
			e.preventDefault();

			// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
			var ifMultipleCodes = $(".rawcode:has(pre:nth-of-type(2)) code").text(); // check if .rawcodes has multiple pre elements

			if(ifMultipleCodes != '') { // has multiple codes
				var text = $(".rawcode").text().trim();
			}
			else { // only 1 code
				var text = $(".rawcode pre code").text().trim();
			}

			navigator.clipboard.writeText(text).then(function() {
				console.log('Async: Copying to clipboard was successful!');
			}, function(err) {
				console.error('Async: Could not copy text: ', err);
			});
		});
	},

	searchRedmine: function() {
		var search_url = "https://projects.geyst.ch/search?q=";

    	function changeLink(url, attribute) {
			$("#search_redmine").attr("href", url + attribute);
		}

		// on Load
		var search = $('#search_text').val();
		changeLink(search_url, search);

		// on Change
		$(document).on("change", "#search_text", function(e) {
			var search = $('#search_text').val();

			changeLink(search_url, search);
		});
	},

	navDropDown: function () {
		$(".mainNav > ul > li > a").on("click", function (e) {
			e.preventDefault();

			$(this).parent('li').siblings('li').removeClass('is-active'); // hide nav of siblings
			$(this).parent('li').toggleClass('is-active'); // toggle nav
			$(this).parent().parent().toggleClass("is-active");
		});

		function closeNav() {
			$(".mainNav > ul > li").removeClass('is-active');
			$(".mainNav > ul").removeClass("is-active");

			// mobile
			$(".mainNav").removeClass('open');
			$(".navToggle").removeClass('is-active');
		}

		// close nav when clicking "esc"
		$(document).keyup(function(e) {
			if(e.key === "Escape") {
				closeNav();
			}
		});

/*
		$("body").not(".mainNav").on("click", function (e) {
			closeNav();
		});
*/


		/*$("section, footer").on("click", function (e) {
			closeNav();
		});*/

	},

    enableButton: function () {
        $(".navToggle").on("click", function (e) {
			$(this).toggleClass('is-active');
        });
    },

    scrollToAnchor: function () {
        $('.side__navigation .primary__list a, .toTop').on('click', function(e) {
			e.preventDefault();

            var scrollAnchor = $(this).attr('href');
            var scrollPoint = $(scrollAnchor).offset().top-50;

            $('body,html').animate({
                scrollTop: scrollPoint
            }, 500);
        });
    },

    showOnTop: function () {
		$(window).scroll(function (e) {
		    if($(window).scrollTop() > 20) {
			    $('.toTop').addClass('show');
			}
			else $('.toTop').removeClass('show');
		});
	},

/*    animateElements: function () {
        timeanimate = setTimeout(function () {
            //$('.intro').addClass('end');
            $('.logo__login, .login__wrapper #rex-yform, .login__wrapper .form-group').addClass('animate');
        },200);
    },*/

/*    skipIntro: function () {
        $('.skip__button').on('click',function (e) {
            e.preventDefault();
            $('.logo__login, .login__wrapper #rex-yform, .login__wrapper .form-group, .intro, .skip__button').addClass('skip');
            clearTimeout(timeanimate);
        })
    },*/

    openNavigation: function () {
        $('.navToggle').on('click', function (e) {
            $('.mainNav').toggleClass('open');
        });
    },

    colorPicker: function() {
		$("#bgcolor").spectrum({
            preferredFormat: "rgb",
			showInput: true,
			change: function(color) {
				$("section article").css("background-color", color.toHexString());
			}
	    })
    },
	magnific: function() {
		//e.preventDefault();

		$('.magnific-iframe').magnificPopup({
			type: 'iframe'
		});
	}};



var accordion = {
    init: function() {
        this.setup();
    },
    setup: function() {
        $( "#accordion" ).accordion({
            collapsible: true,
            active : true,
            autoHeight: false,
            heightStyle: 'content',
            header: "> h1"
        }).sortable({
            axis: "y",
            handle: "h3",

            stop: function( event, ui ) {
                // IE doesn't register the blur when sorting
                // so trigger focusout handlers to remove .ui-state-focus
                ui.item.children( "h1" ).triggerHandler( "focusout" );

                // Refresh accordion to handle new order
                $( this ).accordion( "refresh" );
            }
        });

        $('#accordion').bind('accordionactivate', function(event, ui) {
            if ($(ui.newHeader).offset() != null) {
                ui.newHeader,
                    $("html, body").animate({scrollTop: ($(ui.newHeader).offset().top)-10}, 600);
            }
        });

        $( "#accordion_multiple" ).accordion({
            collapsible: false,
            active : true,
            autoHeight: false,
            heightStyle: 'content',
            header: "> h1"
        }).sortable({
            axis: "y",
            handle: "h4",

            stop: function( event, ui ) {
                // IE doesn't register the blur when sorting
                // so trigger focusout handlers to remove .ui-state-focus
                ui.item.children( "h1" ).triggerHandler( "focusout" );

                // Refresh accordion to handle new order
                $( this ).accordion( "refresh" );
            }
        });
    }
};